import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
    const toast = useToast()
    const records = ref(null)

    // Table Handler
    const columns = [
        { key: 'user', label: 'Full Name', sortable: true },
        { key: 'staffID', sortable: true },
        { key: 'roles', label: 'User Group', sortable: true },
        { key: 'username', label: 'Email/Username', sortable: true },
        { key: 'status', sortable: true },
        { key: 'updated_at', sortable: true },
        'actions',
    ]

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('updated_at')
    const isSortDirDesc = ref(true)

    const refetch = () => {
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store
            .dispatch('cromis-user/list', {
            query: searchQuery.value,
            per_page: perPage.value,
            page: currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
        })
        .then(response => {
            const { users, meta } = response.data

            meta.value = meta

            callback(users)
            totalRecords.value = meta.value.total
            from.value = meta.value.from
            to.value = meta.value.to
        })
        .catch(() => {
            toast({
                component: ToastificationContent,
                props: {
                    title: 'Error fetching users list',
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                },
            })
        })
    }

    const resolveStatusVariant = status => {
        if ('' + status === '1') return 'success'
        if ('' + status === '0') return 'secondary'
        if ('' + status === '-1') return 'danger'
    }

    return {
        fetch,
        refetch,
        columns,
        perPage,
        currentPage,
        totalRecords,
        from,
        to,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,

        resolveStatusVariant,
    }
}